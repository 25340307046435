import { useState, useEffect } from 'react';

import './S3BucketForm.css';


function S3BucketForm({isCreate, values, setValues, errors, isValid, handleChange, handleSubmit, submitButtonName}) {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    setHeaders(values['s3-bucket-headers']);
  }, [values]);

  function handleAddHeader() {
    const newIndex = headers.at(-1) ? headers.at(-1).index + 1 : 0;
    const newHeader = {
      [`s3-bucket-header-${newIndex}-key`]: '',
      [`s3-bucket-header-${newIndex}-value`]: '',
      index: newIndex
    };
    const newHeaders = values['s3-bucket-headers'];
    newHeaders.push(newHeader);

    setValues({...values, 's3-bucket-headers': newHeaders});
  }

  function handleDeleteHeader(e, index) {
    handleChange(e);
    const newHeaders = values['s3-bucket-headers'].filter(h => h.index !== index);

    setValues(
      {...values, 's3-bucket-headers': newHeaders}
    );
  }

  return (
    <div className='s3-bucket-form-container'>
      <form className='account__form' method='post' onSubmit={handleSubmit}>
        <div className='account__input-container'>
          {isCreate &&
          <label htmlFor='s3-bucket-name-id' className='account__input-label'>Name
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-bucket-name-id'
                name='s3-bucket-name'
                value={values['s3-bucket-name'] ? values['s3-bucket-name'] : ''}
                onChange={handleChange}
                required={true}
                disabled={!isCreate}
              />
              <span className={`account__input-error s3-bucket-name-id-error`}>{errors['s3-bucket-name']}</span>
            </div>
          </label>}
          <label htmlFor='s3-bucket-resource-id-id' className='account__input-label'>Resource ID
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-bucket-resource-id-id'
                name='s3-bucket-resource-id'
                value={values['s3-bucket-resource-id'] ? values['s3-bucket-resource-id'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
              />
              <span className={`account__input-error s3-bucket-resource-id-id-error`}>{errors['s3-bucket-resource-id']}</span>
            </div>
          </label>
          {!isCreate && <>
          <label htmlFor='s3-bucket-status-id' className='account__input-label'>Status
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-bucket-status-id'
                name='s3-bucket-status'
                value={values['s3-bucket-status'] ? values['s3-bucket-status'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
              />
              <span className={`account__input-error s3-bucket-status-id-error`}>{errors['s3-bucket-status']}</span>
            </div>
          </label>
          </>}
        </div>
        <div>
          <h3>Headers:</h3>
          <button className='account__button' onClick={handleAddHeader} type='button'>Add header</button>
          {headers && headers.map((header) => (<div key={header.index}> Header {header.index}:
            <label htmlFor={'s3-bucket-header-' + header.index + '-key-id'} className='account__input-label'>Key
              <div className='account__input'>
                <input
                  type='text'
                  className='account__input-field'
                  id={'s3-bucket-header-' + header.index + '-key-id'}
                  name={'s3-bucket-header-' + header.index + '-key'}
                  value={header[`s3-bucket-header-${header.index}-key`] ? header[`s3-bucket-header-${header.index}-key`] : ''}
                  onChange={handleChange}
                />
                <span className={`account__input-error ${'s3-bucket-header-' + header.index}-key-id-error`}>{errors[`s3-bucket-header-${header.index}-key`]}</span>
              </div>
            </label>
            <label htmlFor={'s3-bucket-header-' + header.index + '-value-id'} className='account__input-label'>Value
              <div className='account__input'>
                <input
                  type='text'
                  className='account__input-field'
                  id={'s3-bucket-header-' + header.index + '-value-id'}
                  name={'s3-bucket-header-' + header.index + '-value'}
                  value={header[`s3-bucket-header-${header.index}-value`] ? header[`s3-bucket-header-${header.index}-value`] : ''}
                  onChange={handleChange}
                />
                <span className={`account__input-error ${'s3-bucket-header-' + header.index}-value-id-error`}>{errors[`s3-bucket-header-${header.index}-value`]}</span>
              </div>
            </label>
            <button className='' onClick={(e) => {handleDeleteHeader(e, header.index)}} type='button'>Delete header</button>
            </div>))}
        </div>
        <button className='account__button' disabled={!isValid} type='submit'>{submitButtonName}</button>
      </form>
    </div>
  );
}

export default S3BucketForm;
