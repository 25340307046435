import React from 'react';

import Main from '../Main/Main';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


function Root(props) {
  return (
    <>
      <Header
        loggedIn={props.loggedIn}
        isItWaitingAnswer={props.isItWaitingAnswer}
      />
      <Main
        loggedIn={props.loggedIn}
        accessToken={props.accessToken}
        onHandleUpdateUser={props.onHandleUpdateUser}
        onHandleLogout={props.onHandleLogout}
        isItWaitingAnswer={props.isItWaitingAnswer}
        responseError={props.responseError}
        setResponseError={props.setResponseError}
        responseSucces={props.responseSucces}
        setResponseSucces={props.setResponseSucces}
        isWaitingAnswerCount={props.isWaitingAnswerCount}
        waitingAnswerDispatch={props.waitingAnswerDispatch}
      />
      {!props.loggedIn && <Footer />}
    </>
  )
}

export default Root;
