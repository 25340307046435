import './VodForm.css';

function VodForm({isCreate, values, errors, isValid, handleChange, handleSubmit, submitButtonName}) {

  return (
    <div className='vod-form-container'>
      <form className='account__form' method='post' onSubmit={handleSubmit}>
        <div className='account__input-container'>
          <label htmlFor='vod-source-type-id' className='account__input-label'>Source type
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='vod-source-type-id'
                name='vod-source-type'
                value={values['vod-source-type'] ? values['vod-source-type'] : ''}
                onChange={handleChange}
                required={true}
                disabled={!isCreate}
              />
              <span className={`account__input-error vod-source-type-id-error`}>{errors['vod-source-type']}</span>
            </div>
          </label>
          <label htmlFor='vod-source-id-id' className='account__input-label'>Source ID
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='vod-source-id-id'
                name='vod-source-id'
                value={values['vod-source-id'] ? values['vod-source-id'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
              />
              <span className={`account__input-error vod-source-id-id-error`}>{errors['vod-source-id']}</span>
            </div>
          </label>
          {!isCreate && <>
          <label htmlFor='vod-domain-id' className='account__input-label'>VoD domain
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='vod-domain-id'
                placeholder='exmaple.com'
                name='vod-domain'
                value={values['vod-domain'] ? values['vod-domain'] : ''}
                disabled
              />
              <span className={`account__input-error vod-domain-id-error`}>{errors['vod-domain']}</span>
            </div>
          </label>
          <label htmlFor='vod-is-active-id' className='account__input-label'>Active
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='vod-is-active-id'
                name='vod-is-active'
                value={values['vod-is-active'] ? true : false}
                disabled
              />
              <span className={`account__input-error vod-is-active-id-error`}>{errors['vod-is-active']}</span>
            </div>
          </label>
          </>}
        </div>

        {isCreate && <button className='account__button' disabled={!isValid} >{submitButtonName}</button>}
      </form>
    </div>
  );
}

export default VodForm;
