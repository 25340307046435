import { BaseApi } from './baseApi';

class RatatoskApi extends BaseApi {
  register(firstName, lastName, email, password) {
    return this._sendData(
      `${this._baseUrl}/signup`, 'POST', {firstName, lastName, email, password}
    );
  }

  login(email, password) {
    return this._sendData(`${this._baseUrl}/auth/login/`, 'POST', {email, password});
  }

  loginExternal(email, password, authProvider=undefined) {
    const kwargs = authProvider ? {login:email, password, provider:authProvider} : {login:email, password};
    return this._sendData(`${this._baseUrl}/auth/loginExternal/`, 'POST', kwargs);
  }

  logout(token) {
    return this._getData(`${this._baseUrl}/auth/logout/`, token);
  }

  refreshToken(refresh) {
    return this._sendData(
      `${this._baseUrl}/auth/refresh/`, 'POST', {refresh}
    );
  }

  getUserInfo(token) {
    return this._getData(`${this._baseUrl}/users/me/`, token);
  }

  updateUserInfo(firstName, lastName, email, token) {
    return this._sendData(
      `${this._baseUrl}/users/me`, 'PUT', {firstName, lastName, email, token}
    );
  }

  getProjects(token) {
    return this._getData(`${this._baseUrl}/projects/`, token);
  }

  getProject(id, token) {
    return this._getData(`${this._baseUrl}/projects/${id}/`, token);
  }

  getDomains(token, projectId, type, status, limit=10, offset=0) {
    return this._getData(`${this._baseUrl}/domains/`, token, {project_id: projectId, type, status, limit, offset});
  }

  getDomain(id, token) {
    return this._getData(`${this._baseUrl}/domains/${id}/`, token);
  }

  updateDomain(token, id, canonical_link, redirect_from_80_to_443, port, sitemap, ips) {
    let data = {
      canonical_link,
      redirect_from_80_to_443,
      port,
      ips,
      sitemap
    }

    return this._sendData(`${this._baseUrl}/domains/${id}`, 'PUT', data, token);
  }

  createDomain(token, project_id, name, canonical_link, redirect_from_80_to_443, port, sitemap, type, ips) {
    let data = {
      name,
      project_id,
      canonical_link,
      redirect_from_80_to_443,
      port,
      type,
      ips,
    }

    if (sitemap) {
      data = {...data, sitemap: sitemap};
    }
    return this._sendData(`${this._baseUrl}/domains/`, 'POST', data, token);
  }

  deleteDomain(token, id) {
    return this._sendData(`${this._baseUrl}/domains/${id}/`, 'DELETE', {}, token);
  }

  toggleDomain(token, id, isEnable) {
    const enablePart = isEnable ? 'enableCdn' : 'disableCdn';
    return this._sendData(`${this._baseUrl}/domains/${id}/${enablePart}/`, 'POST', {}, token);
  }

  clearDomainCache(token, id) {
    return this._sendData(`${this._baseUrl}/domains/${id}/clearCache/`, 'POST', {}, token);
  }

  refillDomainCache(token, id) {
    return this._sendData(`${this._baseUrl}/domains/${id}/refillCache/`, 'POST', {}, token);
  }

  getSslCert(token, domainId) {
    return this._getData(`${this._baseUrl}/domains/${domainId}/getCertificate/`, token);
  }

  getTasks({token, projectId, status, subjectType, subjectId, limit, offset}) {
    return this._getData(`${this._baseUrl}/tasks/`, token, {
      project_id: projectId, status, subject_type: subjectType, subject_id: subjectId, limit, offset
    });
  }

  cancelTask({token, id}) {
    return this._sendData(`${this._baseUrl}/tasks/${id}/cancel/`, 'POST', {}, token);
  }

  restartTask({token, id}) {
    return this._sendData(`${this._baseUrl}/tasks/${id}/restart/`, 'POST', {}, token);
  }

  getVods({token, projectId, sourceType, limit=10, offset=0}) {
    return this._getData(`${this._baseUrl}/vod/`, token, {project_id: projectId, source_type: sourceType, limit, offset});
  }

  getVod({token, id}) {
    return this._getData(`${this._baseUrl}/vod/${id}`, token);
  }

  createVod({token, sourceType, sourceId}) {
    const data = {
      source_type: sourceType, source_id: sourceId
    }
    return this._sendData(`${this._baseUrl}/vod/`, 'POST', data, token);
  }

  deleteVod({token, id}) {
    return this._sendData(`${this._baseUrl}/vod/${id}/`, 'DELETE', {}, token);
  }

  toggleVod({token, id, isEnable}) {
    const enablePart = isEnable ? 'enableVod' : 'disableVod';
    return this._sendData(`${this._baseUrl}/vod/${id}/${enablePart}/`, 'POST', {}, token);
  }

  getResources(token, projectId, limit=10, offset=0) {
    return this._getData(`${this._baseUrl}/s3Resources/`, token, {project_id: projectId, limit, offset});
  }

  getS3Resource({token, id}) {
    return this._getData(`${this._baseUrl}/s3Resources/${id}`, token);
  }

  createS3Resource({token, projectId, name, providerId, porviderProto, porviderHost, porviderPort}) {
    const data = {
      project_id: projectId,
      name: name,
      provider_id: providerId,
      provider_proto: porviderProto,
      provider_host: porviderHost,
      provider_port: porviderPort,
    }
    return this._sendData(`${this._baseUrl}/s3Resources/`, 'POST', data, token);
  }

  deleteS3Resource({token, id}) {
    return this._sendData(`${this._baseUrl}/s3Resources/${id}/`, 'DELETE', {}, token);
  }

  getS3Buckets({token, projectId, S3ResourceId, S3ProviderId, limit=10, offset=0}) {
    return this._getData(`${this._baseUrl}/s3Buckets/`, token, {
      project_id: projectId, resource_id: S3ResourceId, provider_id: S3ProviderId, limit, offset
    });
  }

  updateS3Bucket({token, id, headers}) {
    return this._sendData(`${this._baseUrl}/s3Buckets/${id}/`, 'PUT', {headers}, token);
  }

  deleteS3Bucket({token, id}) {
    return this._sendData(`${this._baseUrl}/s3Buckets/${id}/`, 'DELETE', {}, token);
  }

  toggleBucket({token, id, isEnable}) {
    const enablePart = isEnable ? 'enableCdn' : 'disableCdn';
    return this._sendData(`${this._baseUrl}/s3Buckets/${id}/${enablePart}/`, 'POST', {}, token);
  }

  createS3Bucket({token, name, resourceId, headers}) {
    const data = {
      name: name,
      resource_id: resourceId,
      headers: headers,
    }
    return this._sendData(`${this._baseUrl}/s3Buckets/`, 'POST', data, token);
  }
}

const ratatoskApiClient = new RatatoskApi({
  baseUrl: process.env.REACT_APP_RATATOSK_API_URL,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default ratatoskApiClient;
