export class BaseApi {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }

  async _getData(url, token=null, params={}) {
    const headers = token ?
      {...this._headers, Authorization: `Bearer ${token}`} :
      this._headers;
    let fullUrl = new URL(url);

    Object.keys(params).forEach(key => {
      if (params[key] === undefined) return;

      if (params[key] instanceof Array) {
        params[key].forEach(value => {
          fullUrl.searchParams.append(key, value);
        });
      }
      else {
        fullUrl.searchParams.append(key, params[key])
      };
    });

    console.log(`method: GET;\n path:${fullUrl.pathname}\n query params: ${fullUrl.searchParams}`);
    const res = await fetch(fullUrl, {
      method: 'GET',
      headers: headers,
    });

    if (res.ok) {
      return res.json();
    }
    else {
      console.log(await res.json())
    }
    return await Promise.reject(res.status);
  }

  async _sendData(url, method, data, token=null) {
    const headers = token ?
      {...this._headers, Authorization: `Bearer ${token}`} :
      this._headers;

    const res = await fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(data)
    });

    if (data.password) {
      data.password = '';
    }

    console.log(`method: ${method};\n url:${url}; data: ${data}`,data);

    if (!res.ok) {
      console.log(await res.json())
    }

    if (res.status === 204) {
      return
    }

    return res.ok ? res.json() : await Promise.reject(res.status);
  }
}
