import { useEffect, useContext, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import './S3Resources.css';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function S3Resources() {
  const currentProject = useContext(CurrentProjectContext);
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();

  const [resources, setResources] = useState([]);
  const [resourcesLimit, setResourcesLimit] = useState(10);
  const [resourcesOffset, setResourcesOffset] = useState(0);

  useEffect(() => {
    if (currentProject.id === 0) {
      return;
    }

    getResources();
  }, [currentProject.id, resourcesLimit, resourcesOffset]);

  function getResources() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.getResources(accessToken, currentProject.id, resourcesLimit, resourcesOffset)
    .then((res) => {
      setResources(res.results);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleSetS3ResourceLimit() {
    let newLimit = prompt('Insert limit: ', 10);
    setResourcesLimit(newLimit);
  }

  function handleSetS3ResourceOffset() {
    let newOffset = prompt('Insert offset: ', 0);
    setResourcesOffset(newOffset);
  }

  return (
    <div className='s3-resources'>
      <Link to='create'><button className='account__button'>New S3 Resource</button></Link>
      <ul>Limit:<button onClick={handleSetS3ResourceLimit}>{resourcesLimit}</button></ul>
      <ul>Offset:<button onClick={handleSetS3ResourceOffset}>{resourcesOffset}</button></ul>
      <ul className="resourcesList">
        {resources.map((resource) => (
          <li key={resource.id} className='resourcesItem'>
            <Link to={`${resource.id}`}>
              <span>Name:</span> {resource.name}
            </Link>
            <p>CDN S3 domain: {resource.cdn_s3_domain}</p>
            <p>Active: {resource.is_active ? 'True' : 'False'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default S3Resources;
