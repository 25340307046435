import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import './S3BucketCreate.css';
import S3BucketForm from '../S3BucketForm/S3BucketForm';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';


function S3BucketCreate() {
  let { id } = useParams();
  const navigate = useNavigate();
  const {
    values, setValues, handleChange, errors, isValid
  } = useFormWithValidation();
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();

  useEffect(() => {
    let data = {
      's3-bucket-resource-id': id,
      's3-bucket-name': '',
      's3-bucket-headers': [],
    }

    setValues({
      ...values, ...data
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    const headers = values['s3-bucket-headers'].map((header) => {
      const key = `s3-bucket-header-${header.index}-key`;
      const value = `s3-bucket-header-${header.index}-value`;
      return {key: header[key], value: header[value]};
    });

    ratatoskApiClient.createS3Bucket({
      token: accessToken,
      name: values['s3-bucket-name'],
      resourceId: values['s3-bucket-resource-id'],
      headers: headers,
    })
    .then((res) => {
      navigate(`/s3/${res.resource_id}`);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleBucketChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    if (name.startsWith('s3-bucket-header-')) {
      const index = name.split('-')[3];
      values['s3-bucket-headers'].forEach((header) => {
        if (header.index.toString() === index) {
          header[name] = value;
        }
      })

      setValues(values);
    }

    return handleChange(event);
  }

  return (
    <div className='s3-bucket'>
      <h1 className='account__title'>Create S3 Bucket</h1>
      <S3BucketForm
        isCreate={true}
        values={values}
        setValues={setValues}
        errors={errors}
        isValid={isValid}
        handleChange={handleBucketChange}
        handleSubmit={handleSubmit}
        submitButtonName='Create bucket'
      ></S3BucketForm>
    </div>
  );
}

export default S3BucketCreate;
