import './Footer.css';

function Footer() {

  return (
    <footer className='footer'>
      <div className='footer__content'>
        <p className='footer__copyright'>© 2024 Arviol</p>
        <ul className='footer__nav'>
          <li className='footer__nav-item'>
            <a
              href='https://arviol.com/'
              target='_blank'
              rel='noreferrer'
              className='footer__link'
            >
              arviol.com
            </a>
          </li>
          <li className='footer__nav-item'>
            <a
              href='https://gitlab.itglobal.com/cdn/web-panel'
              target='_blank'
              rel='noreferrer'
              className='footer__link'
            >
              Gitlub
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
