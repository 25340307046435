import './S3ResourceForm.css';

function S3ResourceForm({isCreate, values, errors, isValid, handleChange, handleSubmit, submitButtonName}) {

  return (
    <div className='s3-form-container'>
      <form className='account__form' method='post' onSubmit={handleSubmit}>
        <div className='account__input-container'>
          {!isCreate && <label htmlFor='s3-resource-id-id' className='account__input-label'>S3 Resource ID
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-id-id'
                name='s3-resource-id'
                value={values['s3-resource-id'] ? values['s3-resource-id'] : ''}
                onChange={handleChange}
                required={true}
                disabled
              />
              <span className={`account__input-error s3-resource-id-id-error`}>{errors['s3-resource-id']}</span>
            </div>
          </label>}
          <label htmlFor='s3-resource-name-id' className='account__input-label'>Name
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-name-id'
                name='s3-resource-name'
                value={values['s3-resource-name'] ? values['s3-resource-name'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
              />
              <span className={`account__input-error s3-resource-name-id-error`}>{errors['s3-resource-name']}</span>
            </div>
          </label>
          <label htmlFor='s3-resource-provider-id-id' className='account__input-label'>Provider ID
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-provider-id-id'
                name='s3-resource-provider-id'
                value={values['s3-resource-provider-id'] ? values['s3-resource-provider-id'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
                required
              />
              <span className={`account__input-error s3-resource-provider-id-id-error`}>{errors['s3-resource-provider-id']}</span>
            </div>
          </label>
          <label htmlFor='s3-resource-provider-proto-id' className='account__input-label'>Provider proto
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-provider-proto-id'
                name='s3-resource-provider-proto'
                value={values['s3-resource-provider-proto'] ? values['s3-resource-provider-proto'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
                required
              />
              <span className={`account__input-error s3-resource-provider-proto-id-error`}>{errors['s3-resource-provider-proto']}</span>
            </div>
          </label>
          <label htmlFor='s3-resource-provider-host-id' className='account__input-label'>Provider host
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-provider-host-id'
                name='s3-resource-provider-host'
                value={values['s3-resource-provider-host'] ? values['s3-resource-provider-host'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
                required
              />
              <span className={`account__input-error s3-resource-provider-host-id-error`}>{errors['s3-resource-provider-host']}</span>
            </div>
          </label>
          <label htmlFor='s3-resource-provider-port-id' className='account__input-label'>Provider port
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-provider-port-id'
                name='s3-resource-provider-port'
                value={values['s3-resource-provider-port'] ? values['s3-resource-provider-port'] : ''}
                onChange={handleChange}
                disabled={!isCreate}
                required
              />
              <span className={`account__input-error s3-resource-provider-port-id-error`}>{errors['s3-resource-provider-port']}</span>
            </div>
          </label>
          {!isCreate && <>
          <label htmlFor='s3-resource-cdn-s3-domain-id' className='account__input-label'>CDN S3 domain
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-cdn-s3-domain-id'
                placeholder='exmaple.com'
                name='s3-resource-cdn-s3-domain'
                value={values['s3-resource-cdn-s3-domain'] ? values['s3-resource-cdn-s3-domain'] : ''}
                disabled
              />
              <span className={`account__input-error s3-resource-cdn-s3-domain-id-error`}>{errors['s3-resource-cdn-s3-domain']}</span>
            </div>
          </label>
          <label htmlFor='s3-resource-is-active-id' className='account__input-label'>Active
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='s3-resource-is-active-id'
                name='s3-resource-is-active'
                value={values['s3-resource-is-active'] ? true : false}
                disabled
              />
              <span className={`account__input-error s3-resource-is-active-id-error`}>{errors['s3-resource-is-active']}</span>
            </div>
          </label>
          </>}
        </div>

        {isCreate && <button className='account__button' disabled={!isValid} >{submitButtonName}</button>}
      </form>
    </div>
  );
}

export default S3ResourceForm;
