import { useEffect, useContext, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import './Vods.css';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function Vods() {
  const currentProject = useContext(CurrentProjectContext);
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();

  const [vods, setVods] = useState([]);
  const [sourceType, setSourceType] = useState('all');
  const [vodsLimit, setVodsLimit] = useState(10);
  const [vodsOffset, setVodsOffset] = useState(0);

  useEffect(() => {
    if (currentProject.id === 0) {
      return;
    }

    getVods();
  }, [currentProject.id, sourceType, vodsLimit, vodsOffset]);

  function getVods() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    let type = undefined;

    if (sourceType !== 'all')
      type = sourceType

    const data = {
      token: accessToken,
      projectId: currentProject.id,
      sourceType: type,
      limit: vodsLimit,
      offset: vodsOffset,
    }

    ratatoskApiClient.getVods(data)
    .then((res) => {
      console.log(res.results);
      setVods(res.results);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleSetLimit() {
    let newLimit = prompt('Insert limit: ', 10);
    setVodsLimit(newLimit);
  }

  function handleSetOffset() {
    let newOffset = prompt('Insert offset: ', 0);
    setVodsOffset(newOffset);
  }

  return (
    <div className='vods'>
      <Link to='create'><button className='account__button'>New VoD</button></Link>
      <ul>
        Source types:
        <button onClick={() => setSourceType('domain')}>DOMAIN</button>
        <button onClick={() => setSourceType('s3_bucket')}>BUCKET</button>
        <button onClick={() => setSourceType('all')}>ALL</button>
      </ul>

      <ul>Limit:<button onClick={handleSetLimit}>{vodsLimit}</button></ul>
      <ul>Offset:<button onClick={handleSetOffset}>{vodsOffset}</button></ul>
      <ul className="vodsList">
        {vods.map((vod) => (
          <li key={vod.id} className='vodsItem'>
            <Link to={`${vod.id}`}>
              <span>ID:</span> {vod.id}
            </Link>
            <p>Source type: {vod.source_type}</p>
            <p>Source ID: {vod.source_id}</p>
            <p>Active: {vod.is_active ? 'true' : 'false'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Vods;
