import { React, useState, useEffect, useReducer } from 'react';
import { Route, Routes } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import HttpStatus from 'http-status-codes';

import Account from '../Account/Account';
import Login from '../Login/Login';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import ratatoskApiClient from '../../utils/ratatoskApi';
import Register from '../Register/Register';
import Root from '../Root/Root';
import Website from '../Website/Website';
import Websites from '../Websites/Websites';
import WebsiteCreate from '../WebsiteCreate/WebsiteCreate';
import Vod from '../Vod/Vod';
import Vods from '../Vods/Vods';
import VodCreate from '../VodCreate/VodCreate';
import S3Resources from '../S3Resources/S3Resources';
import S3Resource from '../S3Resource/S3Resource';
import S3ResourceCreate from '../S3ResourceCreate/S3ResourceCreate';
import S3BucketCreate from '../S3BucketCreate/S3BucketCreate';

import { authProvider, baseErrMsg } from '../../utils/constants';
import { CurrentUserContext, defaultUser } from '../../contexts/CurrentUserContext';
import { CurrentProjectContext, defaultProject } from '../../contexts/CurrentProjectContext';

import './App.css';


function countReducer(count, action) {
  switch (action.type) {
    case 'inc_count': {
      return count + 1;
    }
    case 'dec_count': {
      return count - 1;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}


function App() {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('loggedIn') === 'true' ? true : false
  );
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [currentUser, setCurrentUser] = useState(defaultUser);
  const [isWaitingAnswerCount, waitingAnswerDispatch] = useReducer(countReducer, 0);
  const [currentProject, setCurrentProject] = useState(defaultProject);
  const [currentProjectId, setCurrentProjectId] = useState(localStorage.getItem('currentProjectId'));
  const [responseError, setResponseError] = useState('');
  const [responseSucces, setResponseSucces] = useState('');

  useEffect(() => { // check access token once when loading the component
    async function fetchData () {
      await checkToken(accessToken);
      waitingAnswerDispatch({type: 'dec_count'});
    }

    waitingAnswerDispatch({type: 'inc_count'});
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { // upload user data every loggedIn or accessToken changing
    if (!loggedIn || !accessToken) {
      return;
    }

    async function fetchUserData() {
      try {
        const userData = await ratatoskApiClient.getUserInfo(accessToken);
        setCurrentUser(userData);
      }
      catch (err) {
          console.log(`Get current user data error: ${err}`);
      }
      finally {
        waitingAnswerDispatch({type: 'dec_count'});
      }
    }

    waitingAnswerDispatch({type: 'inc_count'});
    fetchUserData();
  }, [loggedIn, accessToken]);

  useEffect(() => { // upload user data every loggedIn or accessToken changing
    if (!loggedIn || !accessToken) {
      return;
    }

    async function fetchProjectData() {
      try {
        if (currentProjectId === null) {
          let projects = [];
          projects = await ratatoskApiClient.getProjects(accessToken);
          if (projects.length > 0) {
            setCurrentProjectId(projects[0].id);
            localStorage.setItem('currentProjectId', projects[0].id);
          }
        }

        const project = await ratatoskApiClient.getProject(currentProjectId, accessToken);
        setCurrentProject(project);
      }
      catch (err) {
          console.log(`Get project data error: ${err}`);
      }
      finally {
        waitingAnswerDispatch({type: 'dec_count'});
      }
    }

    waitingAnswerDispatch({type: 'inc_count'});
    fetchProjectData();
  }, [loggedIn, accessToken, currentProjectId]);

  function clearLocalStorage() {
    const literalLastEmail = 'lastEmail';
    const lastEmail = localStorage.getItem(literalLastEmail);
    localStorage.clear();

    if (lastEmail) {
      localStorage.setItem(literalLastEmail, lastEmail);
    }
  }

  function decodeToken(token) {
    const decoded = jwtDecode(token);
    const now = Math.floor(Date.now() / 1000);
    if (decoded.exp <= now) throw new Error('Token expired');
  }

  async function checkToken(token, isRefresh=false) {
    try {
      decodeToken(token);

      if (isRefresh) {
        const {access, refresh} = await ratatoskApiClient.refreshToken(token);
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        await checkToken(access);
        setAccessToken(access);
      }
      else {
        await ratatoskApiClient.getUserInfo(token);
        localStorage.setItem('loggedIn', true);
        setLoggedIn(true);
      }
    }
    catch (err) {
      let consoleErrMsg = 'Token checking error:';
      if (err === HttpStatus.BAD_REQUEST) {
        console.log(`${consoleErrMsg} Bad request`);
      }
      else if (err === HttpStatus.UNAUTHORIZED || err.message === 'Token expired') {
        if (!isRefresh) {
          setAccessToken(null);
          const refreshToken = localStorage.getItem('refreshToken');

          if (refreshToken) {
            await checkToken(refreshToken, true);
            return;
          }
        }
        console.log(`${consoleErrMsg} Invalid credentials`);
      }
      else if (err.name === 'InvalidTokenError') {}
      else {
        console.log(`${consoleErrMsg} ${err}`);
      }

      clearLocalStorage()
      setLoggedIn(false);
    }
  };

  function handleLogin(email, password) {
    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.loginExternal(email, password, authProvider)
    .then((res) => {
      if (res.access && res.refresh) {
        localStorage.setItem('accessToken', res.access);
        localStorage.setItem('refreshToken', res.refresh);
        localStorage.setItem('loggedIn', true);
        localStorage.setItem('lastEmail', email);
        setResponseSucces('You are logged in!');
        setAccessToken(res.access);
        setLoggedIn(true);
      }
    })
    .catch((err) => {
      if (err === HttpStatus.BAD_REQUEST)
        setResponseError('One of the fields is filled in incorrectly');
      else if (err === HttpStatus.UNAUTHORIZED)
        setResponseError('Incorrect username or password');
      else {
        console.log(`Server error: ${err}`)
        setResponseError(baseErrMsg);
      }
      setTimeout(setResponseError, 1500, '');
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleRegister(email, password) {
    waitingAnswerDispatch({type: 'inc_count'})
    localStorage.setItem('loggedIn', true);
    setTimeout(waitingAnswerDispatch, 1000, {type: 'dec_count'});
    setTimeout(setLoggedIn, 1000, true)
  }

  function handleLogout() {
    const res = window.confirm('Are you sure you want to log out?');
    if (!res) return;

    waitingAnswerDispatch({type: 'inc_count'})
    clearLocalStorage();
    setTimeout(waitingAnswerDispatch, 1000, {type: 'dec_count'});
    setTimeout(setLoggedIn, 1000, false)
  }

  function handleUpdateUser(firstName, lastName, email, phone) {
    waitingAnswerDispatch({type: 'inc_count'});
    setCurrentUser({
      firstName,
      lastName,
      email,
      phone,
    })
    setTimeout(waitingAnswerDispatch, 500, {type: 'dec_count'});
    if (Math.random() > 0.5) {
      setTimeout(setResponseError, 500, baseErrMsg);
      setTimeout(setResponseError, 5000, '');
    }
    else {
      setTimeout(setResponseSucces, 500, 'Successfully account updating!');
      setTimeout(setResponseSucces, 3000, '');
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
    <CurrentProjectContext.Provider value={currentProject}>
    <div className='page'>
      <Routes>
        <Route path='/' element={
          <Root
            loggedIn={loggedIn}
            accessToken={accessToken}
            onHandleLogout={handleLogout}
            onHandleUpdateUser={handleUpdateUser}
            isItWaitingAnswer={isWaitingAnswerCount === 0 ? false : true}
            responseError={responseError}
            setResponseError={setResponseError}
            responseSucces={responseSucces}
            setResponseSucces={setResponseSucces}
            isWaitingAnswerCount={isWaitingAnswerCount}
            waitingAnswerDispatch={waitingAnswerDispatch}
          />
        } >
          <Route path='' element={
            <div className='promo__container'>
              <div className='promo__intro'>
                <h1 className='promo__title'>
                  Arviol
                </h1>
                <p className='promo__text-detailed'>Craft your availability and fastest delivery content</p>
              </div>
            </div>
          } />
          <Route path='sites' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><Websites/></ProtectedRoute>
          } />
          <Route path='sites/create' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><WebsiteCreate/></ProtectedRoute>
          } />
          <Route path='sites/:id' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><Website/></ProtectedRoute>
          } />
          <Route path='s3' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><S3Resources/></ProtectedRoute>
          } />
          <Route path='s3/create' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><S3ResourceCreate/></ProtectedRoute>
          } />
          <Route path='s3/:id' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><S3Resource/></ProtectedRoute>
          } />
          <Route path='s3/:id/create-bucket' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><S3BucketCreate/></ProtectedRoute>
          } />
          <Route path='vod' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><Vods/></ProtectedRoute>
          } />
          <Route path='vod/create' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><VodCreate/></ProtectedRoute>
          } />
          <Route path='vod/:id' element={
            <ProtectedRoute loggedIn={loggedIn} navigateTo={'/'}><Vod/></ProtectedRoute>
          } />
          <Route path='streaming' element={<h1 className='promo__title'>Streaming</h1>} />
          <Route path='waf' element={<h1 className='promo__title'>WAF</h1>} />
          <Route path='account' element={<Account/>} />
        </Route>
        <Route path='/signin' element={
          <ProtectedRoute loggedIn={!loggedIn} navigateTo={'/'}>
            <Login
              onHandleLogin={handleLogin}
              isItWaitingAnswer={isWaitingAnswerCount === 0 ? false : true}
              responseError={responseError}
              setResponseError={setResponseError}
            />
          </ProtectedRoute>
        } />
        <Route path='/signup' element={
          <ProtectedRoute loggedIn={!loggedIn} navigateTo={'/'}>
            <Register
              onHandleRegister={handleRegister}
              isItWaitingAnswer={isWaitingAnswerCount === 0 ? false : true}
              responseError={responseError}
              setResponseError={setResponseError}
            />
          </ProtectedRoute>
        } />

        <Route path='*' element={
          <h2>404</h2>
        }/>
      </Routes>
    </div>
    </CurrentProjectContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default App;
