import { useEffect, useContext, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import './Account.css';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { authProvider } from '../../utils/constants';

function Account() {
  const currentUser = useContext(CurrentUserContext);
  const [{
    onHandleUpdateUser,
    onHandleLogout,
    responseError,
    setResponseError,
    responseSucces,
    setResponseSucces,
    isWaitingAnswerCount,
  }] = useOutletContext();
  const {
    values, setValues, handleChange, errors, isValid
  } = useFormWithValidation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setResponseError('');
    setResponseSucces('');
    setIsButtonDisabled(checkUserInfoMatches() || !isValid);
  }, [values]);

  useEffect(() => {
    if (checkUserInfoMatches()) {
      return;
    }

    setValues({...values, 'profile-phone': currentUser.phone, 'profile-email': currentUser.email});
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    e.preventDefault();
    onHandleUpdateUser(
      values['profile-first-name'],
      values['profile-last-name'],
      values['profile-email'],
      values['profile-phone'],
    );
    setIsButtonDisabled(true);
  }

  function checkUserInfoMatches() {
    return (
      currentUser.firstName === values['profile-first-name'] &&
      currentUser.lastName === values['profile-last-name'] &&
      currentUser.email === values['profile-email'] &&
      currentUser.phone === values['profile-phone']
    );
  }

  return (
    <div className='account'>
      <h1 className='account__title'>Account settings</h1>
      <form className='account__form' method='post' onSubmit={handleSubmit}>
        <div className='account__input-container'>
          <label htmlFor='account-first-name-id' className='account__input-label'>First name
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='account-first-name-id'
                placeholder='First name'
                minLength='2'
                maxLength='100'
                name='profile-first-name'
                value={values['profile-first-name'] ? values['profile-first-name'] : ''}
                onChange={handleChange}
                disabled={isWaitingAnswerCount !== 0}
              />
              <span className={`account__input-error account-first-name-id-error`}>{errors['profile-first-name']}</span>
            </div>
          </label>
          <label htmlFor='account-last-name-id' className='account__input-label'>Last name
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='account-last-name-id'
                placeholder='Last name'
                minLength='2'
                maxLength='100'
                name='profile-last-name'
                value={values['profile-last-name'] ? values['profile-last-name'] : ''}
                onChange={handleChange}
                disabled={isWaitingAnswerCount !== 0}
              />
              <span className={`account__input-error account-last-name-id-error`}>{errors['profile-last-name']}</span>
            </div>
          </label>
          <label htmlFor='account-email-id' className='account__input-label'>Email
            <div className='account__input'>
              <input
                type='email'
                className='account__input-field'
                id='account-email-id'
                placeholder='Email'
                minLength='2'
                maxLength='100'
                name='profile-email'
                value={values['profile-email'] ? values['profile-email'] : ''}
                onChange={handleChange}
                required={true}
                disabled={authProvider || isWaitingAnswerCount !== 0}
              />
              <span className={`account__input-error account-email-id-error`}>{errors['profile-email']}</span>
            </div>
          </label>
          <label htmlFor='account-phone-id' className='account__input-label'>Phone
            <div className='account__input'>
              <input
                type='tel'
                className='account__input-field'
                id='account-phone-id'
                placeholder='phone'
                minLength='10'
                maxLength='12'
                name='profile-phone'
                value={values['profile-phone'] ? values['profile-phone'] : ''}
                onChange={handleChange}
                required={true}
                disabled={authProvider || isWaitingAnswerCount !== 0}
              />
              <span className={`account__input-error account-phone-id-error`}>{errors['profile-phone']}</span>
            </div>
          </label>
        </div>
        <p className="account__message account__message_type_success">{responseSucces}</p>
        <p className="account__message account__message_type_error">{responseError}</p>
        <button
          className='account__button'
          disabled={isWaitingAnswerCount !== 0 || isButtonDisabled}
        >Save
        </button>
      </form>
      <div className='account__horizontal-divider'></div>
      <div className='account__link-container'>
        <button className='account__link-button' disabled={authProvider || isWaitingAnswerCount !== 0}>Change password</button>
        <button
          className='account__link-button'
          disabled={isWaitingAnswerCount !== 0}
          onClick={onHandleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default Account;
