import { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams, useOutletContext, Link } from 'react-router-dom';

import './S3Resource.css';
import S3ResourceForm from '../S3ResourceForm/S3ResourceForm';
import S3Bucket from '../S3Bucket/S3Bucket';
import Tasks from '../Tasks/Tasks';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function S3Resource() {
  let { id } = useParams();
  const navigate = useNavigate();
  const currentProject = useContext(CurrentProjectContext);
  const {values, setValues, handleChange, errors} = useFormWithValidation();
  const [{accessToken, isWaitingAnswerCount, waitingAnswerDispatch}] = useOutletContext();

  const [resource, setResource] = useState({});
  const [buckets, setBuckets] = useState([]);
  const [bucketsLimit, setBucketsLimit] = useState(20);
  const [bucketsOffset, setBucketsOffset] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [tasksChecked, setTasksChecked] = useState(false);
  const tasksParams = {
    token: accessToken,
    projectId: currentProject.id,
    status: ['IN_PROGRESS', 'FAILED'],
    subjectType: 's3_resource',
    subjectId: id,
    limit: 100,
    offset: 0,
  }

  useEffect(() => {
    if (!tasksChecked || !resource || currentProject.id === 0) {
      return;
    }

    getResource();
  }, [currentProject.id, tasksChecked]);

  useEffect(() => {
    if (!tasksChecked || !resource) {
      return;
    }

    getBuckets();

  }, [resource, tasksChecked, bucketsLimit, bucketsOffset])

  function getResource() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.getS3Resource({token: accessToken, id})
    .then((res) => {
      setResource(res);
    })
    .catch((err) => {
      if (err === 404) {
        navigate('/s3/');
      }
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function getBuckets() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.getS3Buckets({
      token: accessToken,
      projectId: currentProject.id,
      S3ResourceId: resource.id,
      S3ProviderId: resource.provider_id,
      limit: bucketsLimit,
      offset: bucketsOffset,
    })
    .then((res) => {
      setBuckets(res.results);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  useEffect(() => {
    const data = {
      's3-resource-id': resource.id,
      's3-resource-name': resource.name,
      's3-resource-cdn-s3-domain': resource.cdn_s3_domain,
      's3-resource-is-active': resource.is_active,
      's3-resource-provider-id': resource.provider_id,
      's3-resource-provider-proto': resource.provider_proto,
      's3-resource-provider-host': resource.provider_host,
      's3-resource-provider-port': resource.provider_port,
    }

    setValues({
      ...values, ...data
    });
  }, [resource]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleDelete(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    if (!window.confirm('Are you sure?')){
      waitingAnswerDispatch({type: 'dec_count'});
      return;
    }

    ratatoskApiClient.deleteS3Resource({token: accessToken, id: resource.id})
    .then(() => {
      setTasksChecked(false);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleSetS3BucketsLimit() {
    let newLimit = prompt('Insert limit: ', 10);
    setBucketsLimit(newLimit);
  }

  function handleSetS3BucketsOffset() {
    let newOffset = prompt('Insert offset: ', 0);
    setBucketsOffset(newOffset);
  }

  return (
    <div className='s3-resource'>
      <h1 className='account__title'>S3 Resource {resource.name}</h1>
      {!tasksChecked && <Tasks
        accessToken={accessToken}
        tasksParams={tasksParams}
        tasks={tasks}
        setTasks={setTasks}
        tasksChecked={tasksChecked}
        setTasksChecked={setTasksChecked}
      />}

      {tasksChecked && <>
        <S3ResourceForm
          isCreate={false}
          values={values}
          errors={errors}
          handleChange={handleChange}
        ></S3ResourceForm>
        <div>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleDelete}>Delete</button>
        </div>
        <div className='s3__horizontal-divider'></div>
        <div className='buckets'>
          <h2>Resource Buckets:</h2>
          <ul>Limit:<button onClick={handleSetS3BucketsLimit}>{bucketsLimit}</button></ul>
          <ul>Offset:<button onClick={handleSetS3BucketsOffset}>{bucketsOffset}</button></ul>
          <Link to='create-bucket'><button className='account__button'>New S3 Bucket</button></Link>

          <ul className='bucketsList'>
            {buckets.map((bucket) => (
              <div key={bucket.id}>
                <S3Bucket bucket={bucket} buckets={buckets} setBuckets={setBuckets} />
                <div className='s3__horizontal-divider'></div>
              </div>
            ))}
          </ul>

        </div>
      </>}
    </div>
  );
}

export default S3Resource;
