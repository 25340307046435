import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useState } from 'react';

import './Main.css';
import Sidebar from '../Sidebar/Sidebar';
import { minTabletWidth } from '../../utils/constants'


function Main(props) {
  const location = useLocation()
  const [isSidebarOpened, setIsSidebarOpened] = useState(
    document.documentElement.clientWidth > minTabletWidth ? true : false
  );

  function handleToggleSidebar() {
    setIsSidebarOpened(!isSidebarOpened);
  }

  function handleCloseSidebar() {
    setIsSidebarOpened(false);
  }

  return (
    <main className='main'>
      {
        props.loggedIn &&
        <section className='main__sidebar'>
          <Sidebar
            isOpened={isSidebarOpened}
            onHandleToggle={handleToggleSidebar}
            onHandleClose={handleCloseSidebar}
          />
        </section>
      }
      <section className={isSidebarOpened ? 'main__content' : 'main__content main__content_fully'}>
        {(props.loggedIn || location.pathname === '/') ?
          <Outlet context={[{
            accessToken:props.accessToken,
            onHandleUpdateUser:props.onHandleUpdateUser,
            onHandleLogout:props.onHandleLogout,
            isItWaitingAnswer:props.isItWaitingAnswer,
            responseError:props.responseError,
            setResponseError:props.setResponseError,
            responseSucces:props.responseSucces,
            setResponseSucces:props.setResponseSucces,
            isWaitingAnswerCount:props.isWaitingAnswerCount,
            waitingAnswerDispatch:props.waitingAnswerDispatch,
          }]} /> :
          <Navigate to='/'/>}
      </section>
    </main>
  );
}

export default Main;
