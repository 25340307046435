import { useEffect, useContext } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import './WebsiteCreate.css';
import WebsiteForm from '../WebsiteForm/WebsiteForm';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function WebsiteCreate() {
  const navigate = useNavigate();
  const currentProject = useContext(CurrentProjectContext);
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();
  const {
    values, setValues, handleChange, errors, isValid
  } = useFormWithValidation();

  useEffect(() => {
    let data = {
      'website-type': 'cname',
      'website-port': 80,
      'website-redirect': true,
    }

    setValues({
      ...values, ...data
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();
    let ips = [];

    [values['website-ip1'], values['website-ip2']].forEach(ip => {
      if (ip) {ips.push({origin_ip: ip})}
    })

    ratatoskApiClient.createDomain(
      accessToken, currentProject.id,
      values['website-name'],
      values['website-canonical-link'],
      values['website-redirect'],
      values['website-port'],
      values['website-sitemap'],
      values['website-type'],
      ips,
    )
    .then((res) => {
      return navigate(`/sites/${res.id}`);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  return (
    <div className='website'>
      <h1 className='account__title'>Create Website</h1>
      <WebsiteForm
        isCreate={true}
        values={values}
        errors={errors}
        isValid={isValid}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitButtonName={'Create'}
      ></WebsiteForm>
    </div>
  );
}

export default WebsiteCreate;
