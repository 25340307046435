import { Link } from 'react-router-dom';
import { useContext } from 'react';

import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';
import './Header.css';

function Header({loggedIn, isItWaitingAnswer}) {
  const currentProject = useContext(CurrentProjectContext);

  return (
    <header className='header'>
      <Link to='/' className='header__logo' />
      {
        !loggedIn && <ul className='header__auth'>
          <li>
            <Link to='/signup' className='header__link'>Registration</Link>
          </li>
          <li>
            <Link to='/signin'>
              <button className='header__button header__button_enter'>
                Login
              </button>
            </Link>
          </li>
        </ul>
      }
      {
        loggedIn && <ul className='header__auth'>
          <li>Project ID: {currentProject.id}</li>
          <li>Project name: {currentProject.name}</li>
          <li>
            <Link to='/'>
              <button className='header__button header__button_enter' disabled={isItWaitingAnswer} >
                Billing
              </button>
            </Link>
          </li>
          <li>
            <Link to='/account'>
              <button className='header__button header__button_enter' disabled={isItWaitingAnswer} >
                Account
              </button>
            </Link>
          </li>
        </ul>
      }
    </header>
  );
}

export default Header;
