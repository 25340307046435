import './Input.css';

function Input({ properties, value, errors, onHandleChange }) {
  return (
    <div className='input'>
      <input
        className={`input__field ${errors && 'input__field_invalid'}`}
        type={properties.type}
        id={properties.id}
        name={properties.name}
        minLength={properties.minLength}
        maxLength={properties.maxLength}
        required={properties.required}
        onChange={onHandleChange}
        value={value ? value : ''}
        disabled={properties.disabled}
        placeholder={properties.placeholder}
      />
      <span className={`input__error ${properties.id}-error`}>{errors}</span>
    </div>
  );
}

export default Input;
