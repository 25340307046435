import { useEffect, useContext, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import './Websites.css';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function Websites() {
  const currentProject = useContext(CurrentProjectContext);
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();

  const [websites, setWebsites] = useState([]);
  const [websiteType, setWebsiteType] = useState('all');
  const [websiteStatus, setWebsiteStatus] = useState('all');
  const [websiteLimit, setWebsiteLimit] = useState(10);
  const [websiteOffset, setWebsiteOffset] = useState(0);

  useEffect(() => {
    if (currentProject.id === 0) {
      return;
    }

    getWebsites();
  }, [currentProject.id, websiteType, websiteStatus, websiteLimit, websiteOffset]);

  function getWebsites() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    let type = undefined;
    let status = undefined;

    if (websiteType !== 'all')
      type = websiteType
    if (websiteStatus !== 'all')
      status = websiteStatus

    ratatoskApiClient.getDomains(accessToken, currentProject.id, type, status, websiteLimit, websiteOffset)
    .then((res) => {
      setWebsites(res.results);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleSetWebsiteLimit() {
    let newLimit = prompt('Insert limit: ', 10);
    setWebsiteLimit(newLimit);
  }

  function handleSetWebsiteOffset() {
    let newOffset = prompt('Insert offset: ', 0);
    setWebsiteOffset(newOffset);
  }

  return (
    <div className='websites'>
      <Link to='create'><button className='account__button'>New Website</button></Link>
      <ul>
        Types:
        <button onClick={() => setWebsiteType('delegated')}>DELEGATED</button>
        <button onClick={() => setWebsiteType('cname')}>CNAME</button>
        <button onClick={() => setWebsiteType('all')}>ALL</button>
      </ul>
      <ul>
        Status:
        <button onClick={() => setWebsiteStatus('ok')}>OK</button>
        <button onClick={() => setWebsiteStatus('disabled')}>DISABLED</button>
        <button onClick={() => setWebsiteStatus('blocked')}>BLOCKED</button>
        <button onClick={() => setWebsiteStatus('all')}>ALL</button>
      </ul>
      <ul>Limit:<button onClick={handleSetWebsiteLimit}>{websiteLimit}</button></ul>
      <ul>Offset:<button onClick={handleSetWebsiteOffset}>{websiteOffset}</button></ul>
      <ul className="websitesList">
        {websites.map((website) => (
          <li key={website.id} className='websitesItem'>
            <Link to={`${website.id}`}>
              <span>Name:</span> {website.name}
            </Link>
            <p>Type: {website.type}</p>
            <p>Status: {website.status}</p>
            <p>CNAME url: {website.cname_url}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Websites;
