import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import Input from '../Input/Input';
import './Login.css';

function Login({ onHandleLogin, isItWaitingAnswer, responseError, setResponseError }) {
  const {
    values, handleChange, errors, isValid, resetForm
  } = useFormWithValidation();

  useEffect(() => {
    setResponseError('');
  }, [setResponseError, values]);

  useEffect(() => {
    const lastEmail = localStorage.getItem('lastEmail');
    resetForm({'profile-email': lastEmail});
  }, [resetForm]);

  function handleSubmit(e) {
    e.preventDefault();
    onHandleLogin(
      values['profile-email'],
      values['profile-password'],
    );
  }

  return (
    <section className='login'>
      <Link to='/' className='login__logo' />
      <div className='login__container'>
        <form className='login__form' method='post' onSubmit={handleSubmit}>
          <Input properties={{
            id: 'profile-email-id',
            name: 'profile-email',
            type: 'email',
            placeholder: 'Email',
            minLength: 2,
            maxLength: 40,
            required: true,
            disabled: isItWaitingAnswer
          }} value={values['profile-email']} errors={errors['profile-email']} onHandleChange={handleChange} />
          <Input properties={{
            id: 'profile-password-id',
            name: 'profile-password',
            type: 'password',
            placeholder: 'Password',
            minLength: 8,
            maxLength: 40,
            required: true,
            disabled: isItWaitingAnswer
          }} value={values['profile-password']} errors={errors['profile-password']} onHandleChange={handleChange} />
          <p className="login__error">{responseError}</p>
          <button className='login__submit' type='submit' disabled={isItWaitingAnswer || !isValid}>Login</button>
        </form>
        <div className='login__link-container'>
          <span className='login__question'>Not registered yet?</span>
          <Link to='/signup' className='login__link'>Register</Link>
        </div>
      </div>
    </section>
  );
}

export default Login;
