import { useEffect, useContext, useState } from 'react';

import './Tasks.css';

import { handleGetTasks, handelCancelTask, handelRestartTask } from '../../utils/tasksMethods'
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function Tasks({accessToken, tasksParams, tasks, setTasks, tasksChecked, setTasksChecked}) {
  const currentProject = useContext(CurrentProjectContext);

  useEffect(() => {
    if (tasksChecked || !currentProject.id || !accessToken) return;
    let timeoutId;

    (function check() {
      handleGetTasks(tasksParams).then((tasks) => {
        setTasks(tasks);
        if (tasks.length !== 0) timeoutId = setTimeout(check, 2000);
        else setTasksChecked(true);
      });
    })();

    return () => {clearTimeout(timeoutId)};
  }, [tasksChecked, currentProject.id, accessToken]);

  return (
    <div className='tasks'>
      {tasks.map(task => {
        return (
          <p key={task.id}>
            Task: type={task.task_type}, status={task.status}, Progress={task.percentage ? task.percentage : 0}%.
            {/* {(task.status !== 'CANCELLING' && task.status !== 'TO_CANCELLING') &&
              <button onClick={() => handelCancelTask(accessToken, task.id)}>Cancel task</button>
            } */}
            {task.status === 'FAILED' &&
              <button onClick={() => handelRestartTask(accessToken, task.id)}>Restart task</button>
            }
          </p>
        )
      })}
    </div>
  );
}

export default Tasks;
