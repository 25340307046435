import { NavLink, useLocation } from 'react-router-dom';

import './Sidebar.css';
import { minTabletWidth } from '../../utils/constants';


function Sidebar({ isOpened, onHandleToggle, onHandleClose}) {
  const location = useLocation();

  const getLinkClass = (path) => (
    location.pathname.startsWith(path) ? 'sidebar__button sidebar__button_active' : 'sidebar__button'
  );

  function handleCloseSidebar() {
    document.documentElement.clientWidth < minTabletWidth && onHandleClose();
  }

  return (
    <ul className={isOpened ? 'sidebar' : 'sidebar sidebar_closed'}>
      <li>
        <NavLink to='/sites' className='sidebar__link' onClick={handleCloseSidebar}>
          {isOpened ?
            <button className={getLinkClass('/sites')}>Websites</button> :
            <button className={getLinkClass('/sites') + ' sidebar__mini-button'}>WS</button>
          }
        </NavLink>
      </li>
      <li>
        <NavLink to='/s3' className='sidebar__link' onClick={handleCloseSidebar}>
          {isOpened ?
            <button className={getLinkClass('/s3')}>S3</button> :
            <button className={getLinkClass('/s3') + ' sidebar__mini-button'}>S3</button>
          }
        </NavLink>
        </li>
      <li>
        <NavLink to='/streaming' className='sidebar__link' onClick={handleCloseSidebar}>
          {isOpened ?
            <button className={getLinkClass('/streaming')}>Streaming</button> :
            <button className={getLinkClass('/streaming') + ' sidebar__mini-button'}>STR</button>
          }
        </NavLink>
        </li>
      <li>
        <NavLink to='/waf' className='sidebar__link' onClick={handleCloseSidebar}>
          {isOpened ?
            <button className={getLinkClass('/waf')}>WAF</button> :
            <button className={getLinkClass('/waf') + ' sidebar__mini-button'}>WAF</button>
          }
        </NavLink>
        </li>
      <li>
        <NavLink to='/vod' className='sidebar__link' onClick={handleCloseSidebar}>
          {isOpened ?
            <button className={getLinkClass('/vod')}>VoD</button> :
            <button className={getLinkClass('/vod') + ' sidebar__mini-button'}>VoD</button>
          }
        </NavLink>
      </li>
      <button className='sidebar__toggle-button' onClick={onHandleToggle}>{isOpened ? '<' : '>'}</button>
    </ul>
  );
}

export default Sidebar;
