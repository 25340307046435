import { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';

import './Vod.css';
import VodForm from '../VodForm/VodForm';
import Tasks from '../Tasks/Tasks';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function Vod() {
  let { id } = useParams();
  const navigate = useNavigate();
  const currentProject = useContext(CurrentProjectContext);
  const {values, setValues, handleChange, errors} = useFormWithValidation();
  const [{accessToken, isWaitingAnswerCount, waitingAnswerDispatch}] = useOutletContext();

  const [vod, setVod] = useState({});
  const [tasks, setTasks] = useState([]);
  const [tasksChecked, setTasksChecked] = useState(false);
  const tasksParams = {
    token: accessToken,
    projectId: currentProject.id,
    status: ['IN_PROGRESS', 'FAILED'],
    subjectType: 'vod',
    subjectId: id,
    limit: 100,
    offset: 0,
  }

  useEffect(() => {
    if (!tasksChecked || !vod || currentProject.id === 0) {
      return;
    }

    getVod();
  }, [currentProject.id, tasksChecked]);

  function getVod() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.getVod({token: accessToken, id})
    .then((res) => {
      setVod(res);
    })
    .catch((err) => {
      if (err === 404) {
        navigate('/vod/');
      }
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  useEffect(() => {
    const data = {
      'vod-id': vod.id,
      'vod-source-type': vod.source_type,
      'vod-source-id': vod.source_id,
      'vod-domain': vod.vod_domain,
      'vod-is-active': vod.is_active,
    }

    setValues({
      ...values, ...data
    });
  }, [vod]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleDelete(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    if (!window.confirm('Are you sure?')){
      waitingAnswerDispatch({type: 'dec_count'});
      return;
    }

    ratatoskApiClient.deleteVod({token: accessToken, id: vod.id})
    .then(() => {
      return navigate('/vod/');
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleEnableToggle(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    ratatoskApiClient.toggleVod({token: accessToken, id: vod.id, isEnable: !vod.is_active})
    .then((res) => {
      setTasksChecked(false);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  return (
    <div className='vod'>
      <h1 className='account__title'>VoD {vod.name}</h1>
      {!tasksChecked && <Tasks
        accessToken={accessToken}
        tasksParams={tasksParams}
        tasks={tasks}
        setTasks={setTasks}
        tasksChecked={tasksChecked}
        setTasksChecked={setTasksChecked}
      />}

      {tasksChecked && <>
        <VodForm
          isCreate={false}
          values={values}
          errors={errors}
          handleChange={handleChange}
        ></VodForm>

        <div>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleEnableToggle}>{vod.is_active ? 'Disable' : 'Enable'}</button>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleDelete}>Delete</button>
        </div>
      </>}
    </div>
  );
}

export default Vod;
