import './WebsiteForm.css';

function WebsiteForm({isCreate, values, errors, isValid, handleChange, handleSubmit, submitButtonName}) {

  return (
    <div className='website'>
      <form className='account__form' method='post' onSubmit={handleSubmit}>
        <div className='account__input-container'>
          <label htmlFor='website-name-id' className='account__input-label'>Name
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-name-id'
                name='website-name'
                minLength='2'
                maxLength='256'
                value={values['website-name'] ? values['website-name'] : ''}
                placeholder='example.com'
                onChange={handleChange}
                required={isCreate}
                disabled={!isCreate}
              />
              <span className={`account__input-error website-name-id-error`}>{errors['website-name']}</span>
            </div>
          </label>
          <label htmlFor='website-type-id' className='account__input-label'>Type
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-type-id'
                name='website-type'
                value={values['website-type'] ? values['website-type'] : ''}
                placeholder='delegated/cname'
                onChange={handleChange}
                required={true}
                disabled={!isCreate}
              />
              <span className={`account__input-error website-type-id-error`}>{errors['website-type']}</span>
            </div>
          </label>
          {!isCreate && <>
          <label htmlFor='website-status-id' className='account__input-label'>Status
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-status-id'
                placeholder='ok'
                minLength='2'
                maxLength='100'
                name='website-status'
                value={values['website-status'] ? values['website-status'] : ''}
                onChange={handleChange}
                required={true}
                disabled
              />
              <span className={`account__input-error website-status-id-error`}>{errors['website-status']}</span>
            </div>
          </label>
          {values['website-type'] === 'cname' &&
          <label htmlFor='website-cname-url-id' className='account__input-label'>CNAME
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-cname-url-id'
                placeholder='CNAME'
                minLength='2'
                maxLength='256'
                name='website-cname-url'
                value={values['website-cname-url'] ? values['website-cname-url'] : ''}
                onChange={handleChange}
                disabled
              />
              <span className={`account__input-error website-cname-url-id-error`}>{errors['website-cname-url']}</span>
            </div>
          </label>}</>}
          <label htmlFor='website-canonical-link-id' className='account__input-label'>Canonical link
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-canonical-link-id'
                placeholder='https://example.com'
                minLength='3'
                maxLength='256'
                name='website-canonical-link'
                value={values['website-canonical-link'] ? values['website-canonical-link'] : ''}
                onChange={handleChange}
              />
              <span className={`account__input-error website-canonical-link-id-error`}>{errors['website-canonical-link']}</span>
            </div>
          </label>
          <label htmlFor='website-port-id' className='account__input-label'>Port
            <div className='account__input'>
              <input
                type='number'
                className='account__input-field'
                id='website-port-id'
                min='2'
                max='65535'
                name='website-port'
                value={values['website-port'] ? values['website-port'] : ''}
                onChange={handleChange}
                placeholder='443'
              />
              <span className={`account__input-error website-port-id-error`}>{errors['website-port']}</span>
            </div>
          </label>
          <label htmlFor='website-sitemap-id' className='account__input-label'>Sitemap
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-sitemap-id'
                minLength='2'
                maxLength='256'
                name='website-sitemap'
                value={values['website-sitemap'] ? values['website-sitemap'] : ''}
                onChange={handleChange}
                placeholder='https://example.com/sitemap.xml'
              />
              <span className={`account__input-error website-sitemap-id-error`}>{errors['website-sitemap']}</span>
            </div>
          </label>
          <label htmlFor='website-redirect-id' className='account__input-label'>Redirect
            <div className='account__input'>
              <input
                type='checkbox'
                className='account__input-field'
                id='website-redirect-id'
                name='website-redirect'
                defaultChecked={isCreate ? true : values['website-redirect']}
                onClick={handleChange}
              />
              <span className={`account__input-error website-redirect-error`}>{errors['website-redirect']}</span>
            </div>
          </label>
          {values['website-type'] === 'delegated' && <>
          <label htmlFor='website-ip1-id' className='account__input-label'>Origin IP 1
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-ip1-id'
                minLength='7'
                maxLength='15'
                name='website-ip1'
                value={values['website-ip1'] ? values['website-ip1'] : ''}
                onChange={handleChange}
                required
                placeholder='192.168.1.1'
              />
              <span className={`account__input-error website-ip1-id-error`}>{errors['website-ip1']}</span>
            </div>
          </label>
          <label htmlFor='website-ip2-id' className='account__input-label'>Origin IP 2
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-ip2-id'
                minLength='7'
                maxLength='15'
                name='website-ip2'
                value={values['website-ip2'] ? values['website-ip2'] : ''}
                onChange={handleChange}
                placeholder='192.168.1.2'
              />
              <span className={`account__input-error website-ip2-id-error`}>{errors['website-ip2']}</span>
            </div>
          </label>
          </>}
          <label htmlFor='website-last-refill-id' className='account__input-label'>Last cache refill
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-last-refill-id'
                name='website-last-refill'
                value={values['website-last-refill'] ? values['website-last-refill'] : 'not been refilled yet'}
                onChange={handleChange}
                disabled={true}
              />
              <span className={`account__input-error website-last-refill-id-error`}>{errors['website-last-refill']}</span>
            </div>
          </label>
          <label htmlFor='website-last-cleanup-id' className='account__input-label'>Last cache cleanup
            <div className='account__input'>
              <input
                type='text'
                className='account__input-field'
                id='website-last-cleanup-id'
                name='website-last-cleanup'
                value={values['website-last-cleanup'] ? values['website-last-cleanup'] : 'not been cleaned yet'}
                onChange={handleChange}
                disabled={true}
              />
              <span className={`account__input-error website-last-cleanup-id-error`}>{errors['website-last-cleanup']}</span>
            </div>
          </label>
        </div>

        <button className='account__button' disabled={!isValid} >{submitButtonName}</button>
      </form>
    </div>
  );
}

export default WebsiteForm;
