import { useEffect, useContext } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import './S3ResourceCreate.css';
import S3ResourceForm from '../S3ResourceForm/S3ResourceForm';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';


function S3ResourceCreate() {
  const navigate = useNavigate();
  const currentProject = useContext(CurrentProjectContext);
  const {
    values, setValues, handleChange, errors, isValid
  } = useFormWithValidation();
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();

  useEffect(() => {
    let data = {
      'vod-source-type': 's3_bucket',
      'vod-source-id': '0',
      's3-resource-provider-id': '1',
      's3-resource-provider-proto': 'https',
      's3-resource-provider-port': '443',
    }

    setValues({
      ...values, ...data
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    ratatoskApiClient.createS3Resource({
      token: accessToken,
      projectId: currentProject.id,
      name: values['s3-resource-name'],
      providerId: values['s3-resource-provider-id'],
      porviderProto: values['s3-resource-provider-proto'],
      porviderHost: values['s3-resource-provider-host'],
      porviderPort: values['s3-resource-provider-port'],
    })
    .then((res) => {
      return navigate(`/s3/`);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  return (
    <div className='s3-resource'>
      <h1 className='account__title'>Create S3 Resource</h1>
      <S3ResourceForm
        isCreate={true}
        values={values}
        errors={errors}
        isValid={isValid}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitButtonName={'Create'}
      ></S3ResourceForm>
    </div>
  );
}

export default S3ResourceCreate;
