import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import './VodCreate.css';
import VodForm from '../VodForm/VodForm';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';

function VodCreate() {
  const navigate = useNavigate();
  const {
    values, setValues, handleChange, errors, isValid
  } = useFormWithValidation();
  const [{accessToken, waitingAnswerDispatch}] = useOutletContext();

  useEffect(() => {
    let data = {
      'vod-source-type': 's3_bucket',
      'vod-source-id': '0',
    }

    setValues({
      ...values, ...data
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    ratatoskApiClient.createVod({
      token: accessToken, sourceType: values['vod-source-type'], sourceId: values['vod-source-id']
    })
    .then((res) => {
      return navigate(`/vod/${res.id}`);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  return (
    <div className='vod'>
      <h1 className='account__title'>Create VoD</h1>
      <VodForm
        isCreate={true}
        values={values}
        errors={errors}
        isValid={isValid}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitButtonName={'Create'}
      ></VodForm>
    </div>
  );
}

export default VodCreate;
