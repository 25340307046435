import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import './S3Bucket.css';
import S3BucketForm from '../S3BucketForm/S3BucketForm';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';

function S3Bucket({bucket, buckets, setBuckets}) {
  let { id } = useParams();
  const navigate = useNavigate();
  const {values, setValues, handleChange, errors, isValid, resetForm} = useFormWithValidation();
  const [{accessToken, isWaitingAnswerCount, waitingAnswerDispatch}] = useOutletContext();

  useEffect(() => {
    let headersInValues = [];

    bucket.headers.forEach((header, index) => {
      const key = `s3-bucket-header-${index}-key`;
      const value = `s3-bucket-header-${index}-value`;

      headersInValues.push({
        [key]: header.key,
        [value]: header.value,
        index: index,
      })
      header.index = index;
    });

    const data = {
      's3-bucket-id': bucket.id,
      's3-bucket-name': bucket.name,
      's3-bucket-status': bucket.status,
      's3-bucket-resource-id': bucket.resource_id,
      's3-bucket-headers': headersInValues,
    }

    setValues({
      ...values, ...data
    });
  }, [bucket]); // eslint-disable-line react-hooks/exhaustive-deps

  function localSetBuckets(newBucket) {
    let nextBuckets = buckets.map((bucket) => {
      return bucket.id === newBucket.id ? newBucket : bucket;
    })
    setBuckets(nextBuckets);
  }

  function handleDelete(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    if (!window.confirm('Are you sure?')){
      waitingAnswerDispatch({type: 'dec_count'});
      return;
    }

    ratatoskApiClient.deleteS3Bucket({token: accessToken, id: bucket.id})
    .then(() => {
      setBuckets(buckets.filter(b => b.id !== bucket.id));
      return navigate(`/s3/${id}`);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleToggleBucket() {
    waitingAnswerDispatch({type: 'inc_count'});

    const isEnable = bucket.status.toLowerCase() !== 'ok';

    ratatoskApiClient.toggleBucket({token: accessToken, id: bucket.id, isEnable})
    .then((res) => {
      localSetBuckets(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleSubmit(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    const headers = values['s3-bucket-headers'].map((header) => {
      const key = `s3-bucket-header-${header.index}-key`;
      const value = `s3-bucket-header-${header.index}-value`;
      return {key: header[key], value: header[value]};
    });

    ratatoskApiClient.updateS3Bucket({
      token: accessToken, id: bucket.id, headers
    })
    .then((res) => {
      localSetBuckets(res);
      resetForm();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleBucketChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    if (name.startsWith('s3-bucket-header-')) {
      const index = name.split('-')[3];
      values['s3-bucket-headers'].forEach((header) => {
        if (header.index.toString() === index) {
          header[name] = value;
        }
      })

      setValues(values);
    }

    return handleChange(event);
  }

  return (
    <div className='s3-bucket'>
      <h3>Bucket {bucket.name} ({bucket.id})</h3>
      <S3BucketForm
        isCreate={false}
        values={values}
        setValues={setValues}
        errors={errors}
        isValid={isValid}
        handleChange={handleBucketChange}
        handleSubmit={handleSubmit}
        submitButtonName='Update bucket'
      ></S3BucketForm>
      <div>
        <button
          className='account__button'
          disabled={isWaitingAnswerCount !== 0}
          onClick={handleToggleBucket}
        >
            {bucket.status === 'OK' ? 'Disable' : 'Enable'} bucket
        </button>
        <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleDelete}>Delete bucket</button>
      </div>
    </div>
  );
}

export default S3Bucket;
