import ratatoskApiClient from "./ratatoskApi";

export function handleGetTasks(params={}) {
  return ratatoskApiClient.getTasks(params)
  .then((res) => {
    return res.results;
  })
  .catch((err) => {
    console.log(err);
    return [];
  });
}

export function handelCancelTask(accessToken, id) {
  ratatoskApiClient.cancelTask({token: accessToken, id})
  .then((res) => {
    console.log(res);
  })
  .catch((err) => {
    console.log(err);
  });
}

export function handelRestartTask(accessToken, id) {
  ratatoskApiClient.restartTask({token: accessToken, id})
  .then((res) => {
    console.log(res);
  })
  .catch((err) => {
    console.log(err);
  });
}
