import { useEffect, useContext, useState } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

import './Website.css';
import Tasks from '../Tasks/Tasks';
import WebsiteForm from '../WebsiteForm/WebsiteForm';
import ratatoskApiClient from '../../utils/ratatoskApi';
import { useFormWithValidation } from '../FormValidation/FormValidation';
import { CurrentProjectContext } from '../../contexts/CurrentProjectContext';

function Website() {
  let { id } = useParams();
  const navigate = useNavigate();
  const currentProject = useContext(CurrentProjectContext);
  const [{accessToken, isWaitingAnswerCount, waitingAnswerDispatch}] = useOutletContext();
  const {
    values, setValues, handleChange, errors, isValid, resetForm
  } = useFormWithValidation();

  const [website, setWebsite] = useState({});
  const [sslCert, setSslCert] = useState({});
  const [tasks, setTasks] = useState([]);
  const [tasksChecked, setTasksChecked] = useState(false);
  const tasksParams = {
    token: accessToken,
    projectId: currentProject.id,
    status: ['IN_PROGRESS', 'FAILED'],
    subjectType: 'domain',
    subjectId: id,
    limit: 100,
    offset: 0,
  }

  useEffect(() => {
    if (!tasksChecked || !website || currentProject.id === 0) {
      return;
    }

    getWebsite();
  }, [currentProject.id, tasksChecked]);

  useEffect(() => {
    if (!tasksChecked || !website || currentProject.id === 0) {
      return;
    }

    getSslCert(website.id);
  }, [currentProject.id, tasksChecked, website.id]);

  function getWebsite() {
    if (!accessToken) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.getDomain(id, accessToken)
    .then((res) => {
      setWebsite(res);
    })
    .catch((err) => {
      if (err === 404) {
        navigate('/sites/');
      }
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function getSslCert(domainId) {
    if (!accessToken || !domainId) {
      return;
    }

    waitingAnswerDispatch({type: 'inc_count'})
    ratatoskApiClient.getSslCert(accessToken, domainId)
    .then((res) => {
      setSslCert(res);
    })
    .catch((err) => {
      if (err === 404) {
        return;
      }
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  useEffect(() => {
    let data = {
      'website-name': website.name,
      'website-type': website.type,
      'website-status': website.status,
      'website-redirect': website.redirect_from_80_to_443,
      'website-cname-url': website.cname_url,
      'website-canonical-link': website.canonical_link,
      'website-port': website.port,
      'website-sitemap': website.sitemap ? website.sitemap : '',
      'website-last-refill': website.last_cache_refill,
      'website-last-cleanup': website.last_cache_cleanup,
    }

    if (website.ips && website.ips.length > 0) {
      website.ips.forEach((element, index) => {
        const name = `website-ip${index+1}`;
        data = {...data, [name]: element['origin_ip']}
      });
    }
    setValues({
      ...values, ...data
    });
  }, [website]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();
    let ips = [];

    [values['website-ip1'], values['website-ip2']].forEach(ip => {
      if (ip) {ips.push({origin_ip: ip})}
    })

    ratatoskApiClient.updateDomain(
      accessToken, id=website.id,
      values['website-canonical-link'],
      values['website-redirect'],
      values['website-port'],
      values['website-sitemap'],
      ips,
    )
    .then((res) => {
      setWebsite(res);
      resetForm();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleDelete(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    if (!window.confirm('Are you sure?')){
      waitingAnswerDispatch({type: 'dec_count'});
      return;
    }

    ratatoskApiClient.deleteDomain(accessToken, website.id)
    .then(() => {
      return navigate('/sites/');
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleEnableToggle(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    ratatoskApiClient.toggleDomain(accessToken, website.id, website.status !== 'ok')
    .then((res) => {
      setTasksChecked(false);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleClearCache(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    ratatoskApiClient.clearDomainCache(accessToken, website.id)
    .then((res) => {
      setWebsite(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  function handleRefillCache(e) {
    waitingAnswerDispatch({type: 'inc_count'});
    e.preventDefault();

    ratatoskApiClient.refillDomainCache(accessToken, website.id)
    .then((res) => {
      setWebsite(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      waitingAnswerDispatch({type: 'dec_count'});
    });
  }

  return (
    <div className='website'>
      <h1 className='account__title'>Website {website.name}</h1>
      {!tasksChecked && <Tasks
        accessToken={accessToken}
        tasksParams={tasksParams}
        tasks={tasks}
        setTasks={setTasks}
        tasksChecked={tasksChecked}
        setTasksChecked={setTasksChecked}
      />}
      {tasksChecked && <>
        <WebsiteForm
          isCreate={false}
          values={values}
          errors={errors}
          isValid={isValid}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          submitButtonName={'Save'}
        ></WebsiteForm>
        <div>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleClearCache}>Clear cache</button>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleRefillCache}>Refill cache</button>
        </div>
        <div>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleEnableToggle}>{website.status === 'ok' ? 'Disable' : 'Enable'}</button>
          <button className='account__button' disabled={isWaitingAnswerCount !== 0} onClick={handleDelete}>Delete</button>
        </div>

        {sslCert.domain_id &&
          <div className='sslCert'>
            <h2 style={{textAlignLast: 'center'}}>SSL/TLS Certificate</h2>
            <p>Issuer: {sslCert.issuer}</p>
            <p>Serial: {sslCert.serial}</p>
            <p>Issue date: {sslCert.issue_date}</p>
            <p>Expiry date: {sslCert.expiry_date}</p>
          </div>
        }
      </>}
    </div>
  );
}

export default Website;
